import './App.css'
import Project from './components/Project'
import Contact from './components/Contact'

import React from 'react'

export function Header () {
  return (
    <div className='bg-color-background-default text-white p-4 py-6 pb-0'>
      <p className='text-center text-5xl md:text-5xl lg:text-6xl'>
      The entries at the top (#1 is the best!) are the most worth seeing.
      <br/>
      <br></br>
      To save time, you don't need to look at lower entries!
      </p>

      <p className='text-center text-4xl underline bold text-yellow-200'>
        <br></br>
      DISCLAIMER: Almost all non-code assets were not made by me! I use a ton of free assets!
      </p>
    </div>
  )
}

const projects = [
  {
    title: `Wallrunning + Grapple hook FPS Demo`,
    youtube: 'GahdjiOYyIE',
    body: `Guns are almost from scratch. Animations are by me. Wallrunning is done via raycasted collisions, a custom tweening camera system, and an acceleration based movement system. Bullet physics are from FastCast, and are rendered on the client to prevent machine-gun-network-lag, with extra visuals (like weapon sway) using Spring Module (Hooke's Law).
    `
  },
  {
    title: 'Gravity Hammer With Knockback',
    youtube: 'N8FHwnCtvog',
    body: `AOE hammer that knocks players back from the blast location. Built on my Tool-based FPS framework, using my custom movement system to implement knockback (not Roblox's default).`
  },
  {
    title: `Cosmetics System That Auto Saves (With Cosmetics Dummy)`,
    youtube: 'RU5AXwQ6zuY',
    body: ` Equips cosmetics for gameplay. Auto-saves for logging off. Uses ProfileService to avoid spamming Database queries.
    The dummy is generated clientside.`
  },
  {
    title: 'SCP-173 ("Weeping Angel") Demo',
    youtube: 'H9JHFLL2Uhw',
    body: `Should flawlessly scale to multiple SCPs and players while keeping the gameplay mechanics established by SCP:SL, but was made for a guy as a demo. Uses vector dot product angles + raycasts to test if player "sees" the SCP.
    `
  },
  {
    title: `"Smoother" Custom Reprogrammed Camera`,
    youtube: 'l0pSYXHLu0M',
    body: `Press E and Q to rotate camera 45 degrees.
    Looks simple until you try programming it yourself.
    `
  },
  {
    title: `Dungeon Door Randomization`,
    youtube: 'zAQ3tDA9vsk',
    body: `
    Video: Walls are made transparent. 
    Red doors go to red doors. Green doors go to green doors.
    Doors randomize every 5-10 seconds.
    `
  },
  {
    title: `Spend Limiter To Stop Dupes (With Payment System)`,
    youtube: '_hZD8ojT_xg',
    body: `<u> Simple but important.</u> Uses OS.Time() and math to limit how much $ can be spent in a span of time.
    `
  },
  {
    title: `Poison Gas Cloud Launcher`,
    youtube: 'q3eexn3Y1Y0',
    body: `Cloud hitbox slowly expands and does damage over time to Humanoids inside it.`
  },
  {
    title: `(OLD) Zombie That Remembers Your Last Seen Position`,
    youtube: 'X75cCQTurxs',
    body: `Still cool to look at. Had some problems. Was part of a framework I tried to make for monsters.`
  },
  {
    title: `(OLD) Fireball With Burn Effect Stacking System`,
    youtube: 'ih8FNdpu5GY',
    body: `Tried making an effects stacking system. 
    Used it to make stacking burn effect. Flame goes from 1 to 5 stacks, then 4, 3, 2...`
  },

  /*
  {
    title: ``,
    youtube: '',
    body: ``
  },
 */
]

function App () {
  return (
    <>
      <Contact />
      <Header/>
      {/* primary wrapper */}
      <div className='break-words bg-color-background-default flex'>
        <div className='bg-slate-500 mx-auto p-3 gap-3 container mt-12 grid grid-flow-row auto-rows-max grid-cols-1 md:grid-cols-2 lg:grid-cols-3 rounded-3xl'>
          {projects.map((post, index) => (
            <Project key={post.title + index} project={post} rank={index} />
          ))}
          {/*<Project />*/}
        </div>
      </div>
    </>
  )
}

export default App
